import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { lastValueFrom } from 'rxjs';
import { SSOTokenResponse, TokenResponse } from '../../typings/token.typing';
import { TokenAdapterService } from './token-adapter.service';

@Injectable({ providedIn: 'root' })
export class TokenRetrievalResources {

  constructor (
    private portal: PortalDeterminationService,
    private httpRestService: HttpClient,
    private adapter: TokenAdapterService
  ) { }

  getTokenFromSSO (
    token: string,
    accessToken: string,
    clientIdentifier: string
  ): Promise<SSOTokenResponse> {
    if (this.portal.isApply) {
      return this.getTokenFromSSOApplicant(token, accessToken, clientIdentifier);
    } else if (this.portal.isManager) {
      return this.getTokenFromSSOManager(token, accessToken, clientIdentifier);
    }

    return null;
  }

  private async getTokenFromSSOManager (token: string, accessToken: string, clientIdentifier: string): Promise<SSOTokenResponse> {
    const response = await lastValueFrom(this.httpRestService
      .post<SSOTokenResponse>('/api/token/ManagerSso', {
        token,
        accessToken,
        clientIdentifier
      }));

    return this.adapter.handleTokenRequest(response);
  }

  private async getTokenFromSSOApplicant (token: string, accessToken: string, clientIdentifier: string): Promise<SSOTokenResponse> {
    const response = await lastValueFrom(this.httpRestService
      .post<SSOTokenResponse>('/api/token/PortalSso', {
        token,
        accessToken,
        clientIdentifier
      }));

    return this.adapter.handleTokenRequest(response);
  }

  getPlatformAdminToken (
    code: string,
    clientIdentifier: string
  ): Promise<TokenResponse> {
    const endpoint = 'api/admin/Sso/Token';

    return lastValueFrom(this.httpRestService.post<TokenResponse>(endpoint, {
      code,
      clientIdentifier
    }));
  }
}
