import { Injectable } from '@angular/core';
import { StatusState } from '@core/states/status.state';
import applicationStatuses from '@core/static-assets/application-statuses';
import archiveReasonCodes from '@core/static-assets/archive-reason-codes';
import paymentStatuses from '@core/static-assets/payment-statuses';
import { ApplicantFormForUI, PortalFormAvailabilityInfo } from '@core/typings/application.typing';
import { ApplicationStatuses, BATCH_STATUS, PaymentSubStatus, ReasonCode, StatusMap, StatusOptions } from '@core/typings/status.typing';
import { CancelReasons } from '@core/typings/ui/cancel-application.typing';
import { AvailabilityOptions, DateOption, FORM_STATUS, FormDecisionTypes, FormStatuses } from '@features/configure-forms/form.typing';
import { ALL_SKIP_FILTER, SimpleStringMap } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DateService } from '@yourcause/common/date';
import { I18nService } from '@yourcause/common/i18n';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { addDays } from 'date-fns';

@AttachYCState(StatusState)
@Injectable({ providedIn: 'root' })
export class StatusService extends BaseYCService<StatusState> {

  constructor (
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private dateService: DateService
  ) {
    super();
    this.i18n.language$.subscribe(() => {
      this.reset();
    });
  }

  get paymentStatusMap () {
    return this.get('paymentStatusMap');
  }

  get paymentSubStatusMap () {
    return this.get('paymentSubStatusMap');
  }

  get paymentStatusOptions () {
    return this.get('paymentStatusOptions');
  }

  get formStatusMap () {
    return this.get('formStatusMap');
  }

  get applicationStatusMap () {
    return this.get('applicationStatusMap');
  }

  get formStatusOptions () {
    return this.get('formStatusOptions');
  }

  get adHocStatusOptions () {
    return this.get('adHocAppStatusOptions');
  }

  get appStatusOptions () {
    return this.get('appStatusOptions');
  }

  get archiveReasonCodes () {
    return this.get('archiveReasonCodes');
  }

  get archiveReasonCodeMap () {
    return this.get('archiveReasonCodeMap');
  }

  get notSubmittedAsOfToday () {
    return this.i18n.translate(
      'GLOBAL:textNotSubmittedAsOfToday',
      {
        date: this.dateService.formatDate(new Date())
      },
      'Not submitted as of __date__'
    );
  }

  getDecisionOptions () {
    return [{
      label: this.i18n.translate(
        'common:textYes',
        {},
        'Yes'
      ),
      value: FormDecisionTypes.Approve
    }, {
      label: this.i18n.translate(
        'common:textNo',
        {},
        'No'
      ),
      value: FormDecisionTypes.Decline
    }, {
      label: this.i18n.translate(
        'common:textRecused',
        {},
        'Recused'
      ),
      value: FormDecisionTypes.Recused
    }];
  }


  getDynamicStatusString (
    formStatus: FormStatuses,
    updatedDate: string,
    submittedDate: string,
    revisionLastSentDate: string,
    portalAvailabilityInfo?: PortalFormAvailabilityInfo
  ) {
    if (!!formStatus) {
      if ((formStatus === FormStatuses.NotSent) &&
        (
          !portalAvailabilityInfo ||
          [
            AvailabilityOptions.MANUAL,
            AvailabilityOptions.AUTO
          ].includes(portalAvailabilityInfo?.portalFormAvailabilityType)
        )
      ) {
        // Not sent
        return this.i18n.translate('GLOBAL:textNotSent', {}, 'Not sent');
      } else if (
        [
          FormStatuses.Sent,
          FormStatuses.DraftSaved
        ].includes(formStatus)
      ) {
        // Not submitted, Draft saved
        return this.notSubmittedAsOfToday;
      } else if (formStatus === FormStatuses.NotSent) {
        const statusDate = this.getStatusDate(
          formStatus,
          updatedDate,
          submittedDate,
          revisionLastSentDate,
          portalAvailabilityInfo
        );

        return this.getDynamicSendingString(statusDate, portalAvailabilityInfo);
      }

      return this.i18n.translate(
        'APPLY:textStatusOnDateDynamic',
        {
          status: this.formStatusMap[formStatus].translated,
          date: this.getStatusDate(
            formStatus,
            updatedDate,
            submittedDate,
            revisionLastSentDate,
            portalAvailabilityInfo
          )
        },
        '__status__ on __date__'
      );
    }

    return '';
  }

  getDynamicStatusStringForListView (
    response: ApplicantFormForUI,
    portalAvailabilityInfo?: PortalFormAvailabilityInfo
  ) {
    if (response) {
      if ((response.formStatus === FormStatuses.NotSent) &&
        ((!portalAvailabilityInfo || !portalAvailabilityInfo.portalFormAvailabilityType) ||
        (portalAvailabilityInfo.portalFormAvailabilityType === AvailabilityOptions.MANUAL ||
        (portalAvailabilityInfo.portalFormAvailabilityType === AvailabilityOptions.AUTO)))) {
        // Not sent
        return this.i18n.translate('GLOBAL:textNotSent', {}, 'Not sent');
      } else if (
        [
          FormStatuses.Sent,
          FormStatuses.DraftSaved
        ].includes(response.formStatus)
      ) {
        // Not submitted, Draft saved
        return this.notSubmittedAsOfToday;
      } else if (response.formStatus === FormStatuses.NotSent) {
        const statusDate = this.getStatusDateForListView(response,  portalAvailabilityInfo);

        return this.getDynamicSendingString(statusDate, portalAvailabilityInfo);
      }

      return this.i18n.translate(
        'APPLY:textStatusOnDateDynamic',
        {
          status: this.formStatusMap[response.formStatus].translated,
          date: this.getStatusDateForListView(response,  portalAvailabilityInfo)
        },
        '__status__ on __date__'
      );
    }

    return '';
  }

  getDynamicSendingString (
    date: string,
    portalAvailabilityInfo: PortalFormAvailabilityInfo
  ) {
    const offset = portalAvailabilityInfo.portalFormAvailabilityDateOffset;
    if (!!date) {
      if (this.dateService.isFutureDate(date)) {
        return this.i18n.translate(
          'APPLY:textSendingDate',
          {
            date: this.dateService.formatDate(date)
          },
          'Sending on __date__'
        );
      } else {
        return this.i18n.translate('GLOBAL:textNotSent', {}, 'Not sent');
      }
    } else {
      switch (portalAvailabilityInfo.portalFormAvailabilityDateOption) {
        case DateOption.APPLICATION_APPROVAL_DATE:
          return this.i18n.translate(
            'APPLY:textSendingApprovalDate',
            {
              offset
            },
            'Sending __offset__ days after application approval'
          );
        case DateOption.APPLICATION_SUBMITTED_DATE:
          return this.i18n.translate(
            'APPLY:textSendingOffestApplicationSubmission',
            {
              offset
            },
            'Sending __offset__ days after application submission'
          );
        case DateOption.AWARD_DATE:
          return this.i18n.translate(
            'APPLY:textSendingAwardDate',
            {
              offset
            },
            'Sending __offset__ days after last award date'
          );
        case DateOption.WORKFLOW_LEVEL_ENTRY_DATE:
          return this.i18n.translate(
            'APPLY:textWorkflowLevelEntryDateOffset',
            {
              offset
            },
            'Sending __offset__ days after workflow level entry date'
          );
      }
    }

    return '';
  }

  setPaymentSubStatusMap () {
    const map: StatusMap = {
      [PaymentSubStatus.RETURNED]: {
        name: 'Returned',
        translated: this.i18n.translate(
          'GLOBAL:textReturned',
          {},
          'Returned'
        )
      },
      [PaymentSubStatus.CANCELLED]: {
        name: 'Canceled',
        translated: this.i18n.translate(
          'GLOBAL:textCanceled',
          {},
          'Canceled'
        )
      },
      [PaymentSubStatus.REJECTED_ADDRES_REQUEST]: {
        name: 'Rejected address request',
        translated: this.i18n.translate(
          'GLOBAL:textRejectedAddressRequest',
          {},
          'Rejected address request'
        )
      },
      [PaymentSubStatus.ALTERNATE_ADDRESS_REQUEST]: {
        name: 'Alternate address request',
        translated: this.i18n.translate(
          'MANAGE:textAlternateAddressRequest',
          {},
          'Alternate address request'
        )
      },
      [PaymentSubStatus.RECALLED]: {
        name: 'Recalled',
        translated: this.i18n.translate(
          'MANAGE:textRecalled',
          {},
          'Recalled'
        )
      },
      [PaymentSubStatus.VOIDED_FOR_STALE_CHECK]: {
        name: 'Voided for stale check',
        translated: this.i18n.translate(
          'MANAGE:textVoidedForStaleCheck',
          {},
          'Voided for stale check'
        )
      },
      [PaymentSubStatus.VOIDED_FOR_USPS_CHECK_RETURN]: {
        name: 'Voided for USPS check return',
        translated: this.i18n.translate(
          'MANAGE:textVoidedForUSPSCheckReturn',
          {},
          'Voided for USPS check return'
        )
      },
      [PaymentSubStatus.REISSUE_REQUESTED_BY_CHARITY]: {
        name: 'Reissue requested by charity',
        translated: this.i18n.translate(
          'MANAGE:textReissueRequestedByCharity',
          {},
          'Reissue requested by charity'
        )
      },
      [PaymentSubStatus.INVALID_BANKING_DETAILS]: {
        name: 'Invalid banking details',
        translated: this.i18n.translate(
          'MANAGE:textInvalidBankingDetails',
          {},
          'Invalid banking details'
        )
      },
      [PaymentSubStatus.REJECTED_BY_RECEIVING_BANK]: {
        name: 'Rejected by receiving bank',
        translated: this.i18n.translate(
          'MANAGE:textRejectedByReceivingBank',
          {},
          'Rejected by receiving bank'
        )
      },
      [PaymentSubStatus.FAILED_BLACKBAUD_COMPLIANCE_REVIEW]: {
        name: 'Failed Blackbaud compliance review',
        translated: this.i18n.translate(
          'MANAGE:textFailedBlackbaudComplianceReview',
          {},
          'Failed Blackbaud compliance review'
        )
      }
    };
    this.set('paymentSubStatusMap', map);
  }

  prepareStatuses () {
    this.preparePaymentStatuses();
    this.prepareApplicationStatuses();
    this.prepareFormStatuses();
  }

  preparePaymentStatuses () {
    this.getPaymentStatusMap();
    this.setPaymentSubStatusMap();
    this.setPaymentStatusOptions();
    this.setPaymentSubStatusOptions();
    this.getBatchStatusMap();
  }

  prepareApplicationStatuses () {
    this.getApplicationStatusMap();
    this.setAppStatusOptions();
  }

  prepareFormStatuses () {
    this.getFormStatusMap();
    this.setFormStatusOptions();
  }

  setPaymentStatusOptions () {
    const map = this.paymentStatusMap;
    const statusOptions = Object.keys(map)
      .filter((stat) => +stat !== 0)
      .map((value) => {
        return {
          label: map[value].translated,
          value: +value
        };
      });
    this.set('paymentStatusOptions', statusOptions);
  }

  setPaymentSubStatusOptions () {
    const map = this.paymentSubStatusMap;
    const statusOptions = Object.keys(map)
      .filter((stat) => +stat !== 0)
      .map((value) => {
        return {
          label: map[value].translated,
          value: +value
        };
      });
    this.set('paymentSubStatusOptions', this.arrayHelper.sort(statusOptions, 'label'));
  }

  setAppStatusOptions () {
    const map = this.applicationStatusMap;
    const adHocOptions = Object.keys(map).map((val) => {
      const label = map[val].translated;
      // Use string values b/c that's what the API returns in Ad Hoc

      return {
        label,
        value: '' + val
      };
    });
    this.set('adHocAppStatusOptions', this.arrayHelper.sort(adHocOptions, 'label'));

    // Regular app status options uses the number value
    const appOptions = adHocOptions.map((opt) => {
      return {
        ...opt,
        value: +opt.value
      };
    });

    this.set('appStatusOptions', this.arrayHelper.sort(appOptions, 'label'));
  }

  setFormStatusOptions () {
    const map = this.formStatusMap;
    const statusOptions = Object.keys(map).map((val) => {
      const label = map[val].translated;

      return {
        label,
        value: +val
      };
    });

    this.set('formStatusOptions', this.arrayHelper.sort(statusOptions, 'label'));
  }

  getFormStatusMap () {
    if (!this.get('form')) {
      this.set('form', FORM_STATUS);
    }
    if (!this.formStatusMap) {
      const statusMap = this.setStatusMap(FORM_STATUS);
      this.set('formStatusMap', statusMap);

      return statusMap;
    }

    return this.get('formStatusMap');
  }

  getPaymentStatusMap () {
    if (!this.get('payment')) {
      this.set('payment', paymentStatuses);
    }
    if (!this.paymentStatusMap) {
      const statusMap = this.setStatusMap(paymentStatuses);
      this.set('paymentStatusMap', statusMap);

      return statusMap;
    }

    return this.get('paymentStatusMap');
  }

  getApplicationStatusMap () {
    if (!this.get('application')) {
      this.set('application', applicationStatuses);
    }
    if (!this.applicationStatusMap) {
      const statusMap = this.setStatusMap(applicationStatuses);
      this.set('applicationStatusMap', statusMap);

      return statusMap;
    }

    return this.get('applicationStatusMap');
  }

  getBatchStatusMap () {
    if (!this.get('batch')) {
      this.set('batch', BATCH_STATUS);
    }
    if (!this.get('batchStatusMap')) {
      const statusMap = this.setStatusMap(BATCH_STATUS);
      this.set('batchStatusMap', statusMap);

      return statusMap;
    }

    return this.get('batchStatusMap');
  }

  setStatusMap (statusOptions: StatusOptions[]) {
    const statusMap: StatusMap = {};
    statusOptions.forEach((opt) => {
      if (opt.name === 'Hold') {
        opt.name = 'On hold';
      }
      const result = this.getStatusIconAndTranslation(opt.name);
      statusMap[opt.id] = {
        name: opt.name,
        translated: result.translated,
        icon: result.icon,
        iconClass: result.class,
        tooltipText: result.tooltipText
      };
    });

    return statusMap;
  }

  getStatusIconAndTranslation (name: string) {
    switch (name) {
      case 'Awaiting Review':
        return {
          icon: 'ellipsis-h',
          class: 'text-info',
          translated: this.i18n.translate('GLOBAL:lblAwaitingReview', {}, 'Awaiting review')
        };
      case 'In Progress':
        return {
          icon: 'spinner',
          class: 'text-info',
          translated: this.i18n.translate('GLOBAL:lblInProgress', {}, 'In progress')
        };
      case 'Approved':
       return {
         icon: 'check',
         class: 'text-success',
         translated: this.i18n.translate('GLOBAL:textApproved')
       };
      case 'Declined':
        return {
          icon: 'times',
          class: 'text-danger',
          translated: this.i18n.translate('GLOBAL:textDeclined')
        };
      case 'On hold':
        return {
          icon: 'clock',
          class: 'text-warning',
          translated: this.i18n.translate('GLOBAL:textOnHold', {}, 'On hold')
        };
      case 'Not sent':
        return {
          icon: 'times-circle',
          class: 'text-danger',
          translated: this.i18n.translate('GLOBAL:textNotSent', {}, 'Not sent')
        };
      case 'Not submitted':
        return {
          icon: 'exclamation-circle',
          class: 'text-warning',
          translated: this.i18n.translate('GLOBAL:textNotSubmitted', {}, 'Not submitted')
        };
      case 'Draft saved':
        return {
          icon: 'file-edit',
          class: 'text-info',
          translated: this.i18n.translate('common:textDraft', {}, 'Draft')
        };
      case 'Submitted':
        return {
          icon: 'check-circle',
          class: 'text-success',
          translated: this.i18n.translate('GLOBAL:textSubmitted')
        };
      case 'Revision requested':
        return {
          icon: 'exclamation-circle',
          class: 'text-warning',
          translated: this.i18n.translate('GLOBAL:textRevisionRequested', {}, 'Revision requested')
        };
      case 'Open':
        return {
          icon: 'circle',
          class: 'text-primary',
          translated: this.i18n.translate('GLOBAL:textOpen')
        };
      case 'Reviewed':
        return {
          icon: 'thumbs-up',
          class: 'text-primary',
          translated: this.i18n.translate('GLOBAL:textReviewed')
        };
      case 'Sent to processing - Pending':
        return {
          icon: 'paper-plane',
          class: 'text-primary',
          translated: this.i18n.translate('GLOBAL:textSentToProcessingPending', {}, 'Sent to processing - Pending')
        };
      case 'Funded':
        return {
          icon: 'credit-card',
          class: 'text-success',
          translated: this.i18n.translate('GLOBAL:textFunded')
        };
      case 'Disbursed':
        return {
          icon: 'envelope',
          class: 'text-success',
          translated: this.i18n.translate('GLOBAL:lblDisbursed')
        };
      case 'Complete':
        return {
          icon: 'check-circle',
          class: 'text-success',
          translated: this.i18n.translate('GLOBAL:textComplete')
        };
      case 'Pending':
        return {
          icon: 'circle',
          class: 'text-primary',
          translated: this.i18n.translate('common:lblPending')
        };
      case 'Scheduled':
        return {
          icon: 'calendar-alt',
          class: 'text-primary',
          translated: this.i18n.translate('GLOBAL:textScheduled')
        };
      case 'Processing':
        return {
          icon: 'spinner',
          class: 'text-primary',
          translated: this.i18n.translate('GLOBAL:textProcessing')
        };
      case 'Outstanding':
        return {
          icon: 'envelope',
          class: 'text-primary',
          translated: this.i18n.translate('GLOBAL:textOutstanding')
        };
      case 'Cleared':
        return {
          icon: 'check',
          class: 'text-success',
          translated: this.i18n.translate('GLOBAL:lblCleared')
        };
      case 'Voided':
        return {
          icon: 'ban',
          class: 'text-danger',
          translated: this.i18n.translate('GLOBAL:textVoided')
        };
      case 'Fulfilled':
        return {
          icon: 'check',
          class: 'text-success',
          translated: this.i18n.translate('GLOBAL:textFulfilled')
        };
      case 'Cancelled':
        return {
          icon: 'trash-alt',
          class: 'text-danger',
          translated: this.i18n.translate('GLOBAL:textCanceled')
        };
      case 'ProcessingHold':
        return {
          icon: 'hand-paper',
          class: 'text-warning',
          translated: this.i18n.translate(
            'GLOBAL:textProcessingHold',
            {},
            'Processing - Hold'
          )
        };
        case 'Draft':
          return {
            icon: 'pencil',
            class: 'text-primary',
            translated: this.i18n.translate('common:textDraft',
              {},
              'Draft'
            )
          };
        case 'SentToPartner':
          return {
            icon: 'spinner',
            class: 'text-primary',
            translated: this.i18n.translate('GLOBAL:sentToPartner', {}, 'Sent to Partner'),
            tooltipText: this.i18n.translate(
              'common:textSendToPartnerTooltip',
              {},
              'Payments to this organization are managed by our processing partners. Payment ID will be updated once deposited.'
            )
          };
        case 'Invoiced':
          return {
            icon: 'spinner',
            class: 'text-primary',
            translated: this.i18n.translate('GLOBAL:invoiced', {}, 'Invoiced')
          };
    }

    return {};
  }

  getStatusDate (
    formStatus: FormStatuses,
    updatedDate: string,
    submittedDate: string,
    revisionLastSentDate: string,
    portalAvailabilityInfo: PortalFormAvailabilityInfo
  ) {
    return this.getFormStatusDate(
      formStatus,
      updatedDate,
      submittedDate,
      revisionLastSentDate,
      portalAvailabilityInfo
    );
  }

  getStatusDateForListView (
    response: ApplicantFormForUI,
    portalAvailabilityInfo: PortalFormAvailabilityInfo
  ) {
    return this.getFormStatusDateForListView(
      response.formStatus,
      response.statusUpdatedDate,
      response.formSubmittedOn,
      null,
      portalAvailabilityInfo
    );
  }

  getFormStatusDate (
    statusId: number,
    updatedDate: string,
    submittedDate: string,
    revisionLastSent: string,
    portalAvailabilityInfo?: PortalFormAvailabilityInfo
  ) {
    const formStatusMap = this.getFormStatusMap();
    switch (formStatusMap[statusId].name) {
      case 'Not submitted':
      case 'Draft saved':
      default:
        return this.dateService.formatDate(updatedDate);
      case 'Submitted':
        return this.dateService.formatDate(submittedDate);
      case 'Revision requested':
        return this.dateService.formatDate(revisionLastSent || updatedDate);
      case 'Not sent':
        if (portalAvailabilityInfo) {
          if (portalAvailabilityInfo.portalFormAvailabilityType === AvailabilityOptions.DATE) {
            return this.dateService.formatDate(portalAvailabilityInfo.portalFormAvailabilityDate);
          } else {
            return this.getDateFromPortalAvailabilityInfo(portalAvailabilityInfo);
          }
        }

        return '';
    }
  }

  getDateFromPortalAvailabilityInfo (
    portalAvailabilityInfo: PortalFormAvailabilityInfo
  ) {
    switch (portalAvailabilityInfo.portalFormAvailabilityDateOption) {
      case DateOption.APPLICATION_APPROVAL_DATE:
        return this.getDynamicPortalAvailabilityDate(
          portalAvailabilityInfo.portalFormAvailabilityDynamicDateApplicationApproval,
          +portalAvailabilityInfo.portalFormAvailabilityDateOffset
        );
      case DateOption.APPLICATION_SUBMITTED_DATE:
        return this.getDynamicPortalAvailabilityDate(
          portalAvailabilityInfo.portalFormAvailabilityDynamicDateApplicationSubmission,
          +portalAvailabilityInfo.portalFormAvailabilityDateOffset
        );
      case DateOption.AWARD_DATE:
        return this.getDynamicPortalAvailabilityDate(
          portalAvailabilityInfo.portalFormAvailabilityDynamicDateAwardCreation,
          +portalAvailabilityInfo.portalFormAvailabilityDateOffset
        );
      case DateOption.WORKFLOW_LEVEL_ENTRY_DATE:
        return this.getDynamicPortalAvailabilityDate(
          portalAvailabilityInfo.portalFormAvailabilityDynamicDateWorkflowLevelEntry,
          +portalAvailabilityInfo.portalFormAvailabilityDateOffset
        );
      default:
        return this.dateService.formatDate(portalAvailabilityInfo.portalFormAvailabilityDate);
    }
  }

  getDynamicPortalAvailabilityDate (
    date: string,
    offset: number
  ) {
    if (!!date) {
      const adaptedDate = addDays(new Date(date), offset);

      return this.dateService.formatDate(adaptedDate);
    }

    return null;
  }

  getFormStatusDateForListView (
    statusId: FormStatuses,
    updatedDate: string,
    submittedDate: string,
    revisionLastSent: string,
    portalAvailabilityInfo: PortalFormAvailabilityInfo
  ) {
    switch (statusId) {
      case FormStatuses.DraftSaved:
      default:
        return this.dateService.formatDate(updatedDate);
      case FormStatuses.Submitted:
        return this.dateService.formatDate(submittedDate);
      case FormStatuses.RevisionRequested:
        return this.dateService.formatDate(revisionLastSent || updatedDate);
      case FormStatuses.NotSent:
        if (portalAvailabilityInfo.portalFormAvailabilityType === AvailabilityOptions.DATE) {
          return this.dateService.formatDate(portalAvailabilityInfo.portalFormAvailabilityDate);
        } else {
          return this.getDateFromPortalAvailabilityInfo(portalAvailabilityInfo);
        }
    }
  }

  setArchiveReasonCodes () {
    if (!this.archiveReasonCodes) {
      const codes = archiveReasonCodes;
      const map: SimpleStringMap<string> = {};
      const reasonCodes = codes.map((code: ReasonCode) => {
        const label = this.getReasonDisplay(code.name);
        map[code.code] = label;

        return {
          label,
          value: code.code
        };
      });
      this.set('archiveReasonCodes', reasonCodes);
      this.set('archiveReasonCodeMap', map);
    }
  }

  getReasonDisplay (name: string) {
    switch (name) {
      case 'Completed':
        return this.i18n.translate('GLOBAL:textCompleted');
      case 'Terminated':
        return this.i18n.translate('GLOBAL:textTerminated');
      case 'Declined':
        return this.i18n.translate('GLOBAL:textDeclined');
      case 'Duplicate':
        return this.i18n.translate('GLOBAL:textDuplicate');
      case 'Program Archived':
        return this.i18n.translate('GLOBAL:textProgramArchived', {}, 'Program archived');
      case 'Other':
        return this.i18n.translate('common:textOther');
      default:
        return '';
    }
  }

  getCancelApplicationTypeaheadOptions (): TypeaheadSelectOption<CancelReasons>[] {
    const map = this.getCancelationReasonMap();
    
    return this.arrayHelper.sort(
      Object.keys(map).reduce((acc, reason) => {
        let reasonId = +reason as CancelReasons;

        return [
          ...acc,
          {
            value: reasonId,
            label: map[reasonId]
          }
        ]
      }, []),
      'label'
    );
  }

  getCancelationReasonMap (): Record<CancelReasons, string> {
    return {
      [CancelReasons.FundingNoLongerRequired]: this.i18n.translate(
        'common:textFundingNoLongerRequired',
        {},
        'Funding no longer required'
      ),
      [CancelReasons.ProjectOrProgramCancelledOrDiscontinued]: this.i18n.translate(
        'common:textProjectOrProgramCanceled',
        {},
        'Project or program is canceled or discontinued'
      ),
      [CancelReasons.DuplicateApplication]: this.i18n.translate(
        'common:textDuplicateApplication',
        {},
        'Duplicate application'
      ),
      [CancelReasons.ChangeInCircumstances]: this.i18n.translate(
        'common:textChangeInCircumstance,s',
        {},
        'Change in circumstances'
      ),
      [CancelReasons.NoLongerEligibleForFunding]: this.i18n.translate(
        'common:textNoLongerEligibleForFunding',
        {},
        'No longer eligible for funding'
      ),
      [CancelReasons.Other]: this.i18n.translate(
        'common:textOther',
        {},
        'Other'
      )
    };
  }

  getApplicationStatusTypeaheadOptions (): TypeaheadSelectOption[] {
    return [{
      value: ApplicationStatuses.Draft,
      label: this.i18n.translate('common:textDraft', {}, 'Draft')
    }, {
      value: ApplicationStatuses.AwaitingReview,
      label: this.i18n.translate('GLOBAL:lblAwaitingReview', {}, 'Awaiting review')
    }, {
      value: ApplicationStatuses.InProgress,
      label: this.i18n.translate('GLOBAL:lblInProgress', {}, 'In progress')
    }, {
      value: ApplicationStatuses.Approved,
      label: this.i18n.translate('GLOBAL:textApproved', {}, 'Approved')
    }, {
      value: ApplicationStatuses.Declined,
      label: this.i18n.translate('GLOBAL:textDeclined', {}, 'Declined')
    }, {
      value: ApplicationStatuses.Canceled,
      label: this.i18n.translate('GLOBAL:textCanceled', {}, 'Canceled')
    }, {
      value: ApplicationStatuses.Hold,
      label: this.i18n.translate('GLOBAL:textOnHold', {}, 'On hold')
    }];
  }

  getAppFilterStatusSelects (
    isNomination = false
  ) {
    const statusOptions = {
      selectOptions: this.getApplicationStatusTypeaheadOptions(),
      filterObjectName: this.i18n.translate(
        'common:hdrStatus', {}
      ).toLowerCase(),
      filterObjectNamePlural: this.i18n.translate(
        'common:hdrStatuses', {}
      ).toLowerCase()
    };
    const activeStatusOptions = {
      selectOptions: [{
        label: this.i18n.translate(
          isNomination ?
            'common:textAllNominations' :
            'common:textAllApplications',
          {},
          isNomination ? 'All nominations' : 'All applications'
        ),
        value: ALL_SKIP_FILTER
      },
      {
        label: this.i18n.translate(
          isNomination ?
            'common:textActiveNominations' :
            'common:textActiveApplications',
          {},
          isNomination ? 'Active nominations' : 'Active applications'
        ),
        value: false
      },
      {
        label: this.i18n.translate(
          isNomination ?
            'common:textArchivedNominations' :
            'common:textArchivedApplications',
          {},
          isNomination ? 'Archived nominations' : 'Archived applications'
        ),
        value: true
      }]
    };

    return {
      statusOptions,
      activeStatusOptions
    };
  }

  reset () {
    this.set('applicationStatusMap', undefined);
    this.set('formStatusMap', undefined);
    this.set('paymentStatusMap',  undefined);
    this.prepareStatuses();
  }
}


