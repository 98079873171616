import { CdkDropList } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComponentLimitMetModalComponent } from '@core/components/component-limit-met-modal/component-limit-met-modal.component';
import { AppShellService } from '@core/services/app-shell.service';
import { FooterService } from '@core/services/footer.service';
import { SpinnerService } from '@core/services/spinner.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { environment } from '@environment';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { BuilderConfigGroupType, Form, FormAudience, FormDefinitionComponent, FormDefinitionForUi, FormFieldPasteLocation, MAX_COMPONENTS_PER_PAGE, SampleExternalFields } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { CreateEditFormFieldModalComponent } from '@features/form-fields/create-edit-form-field-modal/create-edit-form-field-modal.component';
import { FormFieldCategoryService } from '@features/form-fields/services/form-field-category.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldService } from '@features/form-fields/services/form-field.service';
import { ComponentHelperService, REF_COMPONENT_TYPE_PREFIX } from '@features/forms/services/component-helper/component-helper.service';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { UserService } from '@features/users/user.service';
import { DragAndDropItem, GoogleService, NoResultsOptions, SimpleStringMap, ToolboxPosition, TopLevelFilter, TypeToken } from '@yourcause/common';
import { ButtonAction } from '@yourcause/common/buttons';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmationModalComponent, ModalFactory } from '@yourcause/common/modals';
import { PanelSection, PanelStyles } from '@yourcause/common/panel';
import { YCTwoWayEmitter } from '@yourcause/common/utils';
import { cloneDeep } from 'lodash';
import { BucketComp, FormBuilderActionEvent, FormBuilderActions } from '../form-builder.typing';
import { RestoreDeletedComponentsModalComponent } from '../restore-deleted-components-modal/restore-deleted-components-modal.component';
import { FormBuilderService } from '../services/form-builder/form-builder.service';

@Component({
  selector: 'gc-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input() form: Form;
  @Input() formRevisionId: number;
  @Input() tabIndex = 0;
  @Input() savingForm = false;
  @Input() quickAddFieldsChanged = false;
  @Input() pageDeletedEmitter: YCTwoWayEmitter<void, FormDefinitionComponent[]>;
  @Output() formChange = new EventEmitter<Form>();
  @Output() numberOfCompsTrackerChanged = new EventEmitter<Record<number, number>>();
  @Output() onCreateNewPage = new EventEmitter();
  @Output() formDetailClicked = new EventEmitter();
  @Output() saveClicked = new EventEmitter<boolean>();
  @Output() cancelClicked = new EventEmitter();
  @Output() onTabIndexChangedForGoToComp = new EventEmitter<number>();
  @Output() onUpdateTabIndex = new EventEmitter<number>();

  sampleExternalFields = SampleExternalFields;
  MaxCompsPerPage = MAX_COMPONENTS_PER_PAGE;
  toolboxPanelSections: PanelSection<never, BucketComp[]>[] = [];
  ToolboxPosition = ToolboxPosition;
  topLevelFilters: TopLevelFilter[] = [
    new TopLevelFilter(
      'typeaheadMultiEquals',
      'categoryId',
      [],
      this.i18n.translate('common:textFilterByCategory', {}, 'Filter by category'),
      {
        selectOptions: this.formFieldCategoryService.categoryOptionsWithOther,
        noBorderRadiusOnInput: true
      },
      undefined,
      undefined,
      true
    )
  ];
  searchPlaceholder = this.i18n.translate(
    'common:textSearchForAFormField',
    {},
    'Search for a form field'
  );
  compHiddenMap: SimpleStringMap<string> = {};
  clientSettings = this.clientSettingsService.get('clientSettings');
  afterInit = false;
  numberOfCompsTracker: Record<number, number> = {};
  enforceMaxComps = false;
  dragBucketId = this.formBuilderService.bucketId;
  typeAudienceMap = this.formService.typeAudienceMap;
  toolboxOpen = true;
  lastValidFormState: FormDefinitionForUi[];
  allDropLists: (CdkDropList|string)[] = [];
  PanelStyles = PanelStyles;
  $panelSections = new TypeToken<PanelSection<never, DragAndDropItem[]>[]>();
  noResultsOptions: NoResultsOptions = {
    leftMessage: this.i18n.translate('common:lblNoResultsFound', {}, 'No results found'),
    rightHeader: this.i18n.translate('common:textNoFieldsFound2', {}, 'No fields found'),
    rightDescription: this.clientSettingsService.isBBGM ?
      '' :
      this.i18n.translate(
        'common:textCreateFormFieldByClickingBelow',
        {},
        'Create a form field by clicking below'
      ),
    rightButtonText: this.clientSettingsService.isBBGM ?
      '' :
      this.i18n.translate('common:textCreateNewFormField', {}, 'Create new form field'),
    rightButtonAction: () => {
      if (!this.clientSettingsService.isBBGM) {
        this.addFormField();
      }
    }
  };
  hasInvalidCut: boolean; // If the component was tied to other components, and was cut without being pasted elsewhere
  isBBGM = this.clientSettingsService.isBBGM;
  saveActions: ButtonAction[] = [{
    i18nKey: 'common:textSaveAndContinueEditing',
    i18nDefault: 'Save and continue editing',
    onClick: () => {
      this.saveChanges(true);
    }
  }, {
    i18nKey: 'common:textSaveAndClose',
    i18nDefault: 'Save and close',
    onClick: () => {
      this.saveChanges();
    }
  }];
  maxCompsPerPageDefaultText = `To maintain performance when creating and filling out forms, only __numberAllowed__ components can be added to one form page. Click "New" at the top to create a new page.`;
  removedCompsWithinSession: FormDefinitionComponent[] = [];
  showSpellCheck = this.userService.user.hasGenAIEntitlement;

  constructor (
    private footerService: FooterService,
    private formService: FormsService,
    private clientSettingsService: ClientSettingsService,
    private spinnerService: SpinnerService,
    private modalFactory: ModalFactory,
    private componentHelper: ComponentHelperService,
    private i18n: I18nService,
    private formBuilderService: FormBuilderService,
    private cdr: ChangeDetectorRef,
    private formFieldHelperService: FormFieldHelperService,
    private formLogicService: FormLogicService,
    private formFieldService: FormFieldService,
    private formFieldCategoryService: FormFieldCategoryService,
    private appShellService: AppShellService,
    private googleService: GoogleService,
    private userService: UserService
  ) { }

  get currentFormDefinition () {
    return this.form.formDefinition[this.tabIndex];
  }

  get isRootZone () {
    return this.clientSettingsService.clientSettings.isRootClient;
  }

  get sidebarClosed () {
    return this.appShellService.sidebarClosed;
  }


  ngOnInit () {
    this.setToolboxPanelSections();
    this.lastValidFormState = cloneDeep(this.form.formDefinition);
    this.afterInit = true;
  }

  ngAfterViewInit () {
    this.loadGoogleForAddressFields();
    setTimeout(() => {
      this.setNumberOfCompsTracker();
      this.setMaxComponentHelpers();
    });
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.form) {
      this.setToolboxPanelSections();
    }
    if (this.afterInit && changes.quickAddFieldsChanged && this.quickAddFieldsChanged) {
      this.setNumberOfCompsTracker();
    }
    if (!!this.pageDeletedEmitter && changes.pageDeletedEmitter) {
      this.pageDeletedEmitter.registerAction((compsDeleted: FormDefinitionComponent[]) => {
        this.removedCompsWithinSession = [
          ...this.removedCompsWithinSession,
          ...compsDeleted
        ];
      });
    }
  }

  loadGoogleForAddressFields () {
    this.googleService.resolve(environment.googleApiKey, this.userService.getCurrentUserCulture());
  }

  async addFormField () {
    const result = await this.modalFactory.open(
      CreateEditFormFieldModalComponent,
      {
        addingFieldToForm: true,
        defaultAudienceSelection: this.formBuilderService.currentFormBuilderFormAudience,
        saveText: this.i18n.translate(
          'common:textSaveAndConfigure',
          {},
          'Save and configure'
        ),
        secondarySaveText: this.i18n.translate(
          'common:textSaveAndViewForm',
          {},
          'Save and view form'
        )
      }
    );
    if (result) {
      const audience = this.formService.getAudienceFromFormType(this.form.formType);
      const comp = await this.formBuilderService.addNewFieldToForm(
        result,
        audience === FormAudience.MANAGER
      );
      if (comp) {
        await this.handleFormChange(this.form.formDefinition);

        // New Fields get added at the bottom of the page, so scroll to it
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });
      }
    }
  }

  saveChanges (continueEditing = false) {
    if (this.hasInvalidCut) {
      this.modalFactory.open(
        ConfirmationModalComponent,
        {
          modalHeader: this.i18n.translate('common:hdrUnableToSave', {}, 'Unable to Save'),
          confirmButtonText: this.i18n.translate('common:textClose', {}, 'Close'),
          confirmText: this.i18n.translate(
            'common:textInvalidCutComponentConfirm',
            {},
            `It appears you started to cut a component but have not finished pasting it. In order to save your changes, the component must be pasted back to the form because other components are dependent on it. To paste, look for the "paste above" and "paste below" arrows when hovering over a component.`
          )
        }
      );
    } else {
      this.saveClicked.emit(continueEditing);
    }
  }

  setToolboxPanelSections () {
    this.toolboxPanelSections = this.formBuilderService.getToolboxPanelSections(
      this.form.formType,
      this.formBuilderService.currentFormBuilderFormAudience
    );
  }

  handleDropListsChanged (dropLists: Iterable<CdkDropList|string>) {
    this.allDropLists = [...dropLists];
    this.cdr.markForCheck();
  }

  handleBucketReady (dropList: CdkDropList) {
    this.formBuilderService.patchDragDrop(dropList._dropListRef);
  }

  async handleComponentAction (
    event: FormBuilderActionEvent
  ) {
    let emitChange = false;
    switch (event.action) {
      case FormBuilderActions.Copy:
        this.handleCopy(event.component);
        break;
      case FormBuilderActions.Cut:
        this.handleCut(event.component);
        break;
      case FormBuilderActions.Paste_Below:
        emitChange = await this.handlePaste(
          event.component,
          FormFieldPasteLocation.BELOW
        );
        break;
      case FormBuilderActions.Paste_Above:
        emitChange = await this.handlePaste(
          event.component,
          FormFieldPasteLocation.ABOVE
        );
        break;
      case FormBuilderActions.Paste_Into_Container:
        emitChange = await this.handlePaste(
          event.component,
          FormFieldPasteLocation.INSIDE_CONTAINER
        );
        break;
      case FormBuilderActions.Edit_Component:
        emitChange = await this.handleEdit(event.component);
        break;
      case FormBuilderActions.Edit_Field:
        emitChange = await this.editFormField(event.component);
        break;
      case FormBuilderActions.Remove:
        emitChange = await this.handleRemove(event.component);
        break;
    }

    if (emitChange) {
      this.handleFormChange(this.form.formDefinition);
    }

    this.setNumberOfCompsTracker();
  }

  async editFormField (component: FormDefinitionComponent) {
    const existingField = this.formFieldHelperService.getReferenceFieldFromCompType(
      component.type
    );
    // If the comp cannot be deleted, that means it's referenced on other components
    // So don't allow key changes
    const compDeletionText = this.formLogicService.getCompDeletionText(
      component,
      this.form.formDefinition
    );
    const isViewOnly = this.isRootZone && existingField.standardComponentIsPublished;
    const result = await this.modalFactory.open(
      CreateEditFormFieldModalComponent,
      {
        existingReferenceField: existingField,
        isViewOnly,
        editingFieldOnForm: true,
        doNotAllowKeyChanges: !!compDeletionText
      }
    );

    if (result && !isViewOnly) {
      const fieldToSave = {
        ...existingField,
        ...result.field
      };
      this.spinnerService.startSpinner();
      await this.formFieldService.handleCreateOrUpdateField(
        existingField.referenceFieldId,
        fieldToSave,
        result.tableFields
      );
      if (existingField.key !== fieldToSave.key) {
        // If the key was changed, we need to make sure the components "type" is updated to match it
        const updatedComponent = {
          ...component,
          type: `${REF_COMPONENT_TYPE_PREFIX}${fieldToSave.key}`
        };
        this.componentHelper.removeOrReplaceFormComponent(
          component,
          this.currentFormDefinition,
          updatedComponent
        );
      }
      this.handleFormChange(this.form.formDefinition);
      this.spinnerService.stopSpinner();
    }

    return false;
  }

  async handleFormChange (
    formDefinition: FormDefinitionForUi[]
  ) {
    let preventChange = false;
    if (await this.hasTooManyComponentsOnPage()) {
      preventChange = true;
    }
    if (preventChange) {
      // this sets the builder state to match the original formDefinition before the change was emitted
      this.form.formDefinition = this.lastValidFormState;
    } else {
      // Allowed to proceed with updating form definition
      this.lastValidFormState = cloneDeep(formDefinition);
      this.form.formDefinition = formDefinition;
    }
    this.form.formDefinition = [
      ...this.form.formDefinition
    ];

    this.setNumberOfCompsTracker();
    this.setToolboxPanelSections();
    this.formChange.emit(this.form);
  }

  setNumberOfCompsTracker () {
    const tracker: Record<number, number> = {};
    this.form.formDefinition.forEach((tab, index) => {
      let numberOfComponents = 0;
      this.componentHelper.eachComponent(tab.components, (component) => {
        if (component.type !== 'button') {
          numberOfComponents = numberOfComponents + 1;
        }
      });
      tracker[index] = numberOfComponents;
    });
    this.numberOfCompsTracker = tracker;
    this.numberOfCompsTrackerChanged.emit(this.numberOfCompsTracker);
  }

  /* If the form already had more than 100 components to begin with, they are grandfathered in and it's not enforced */
  setMaxComponentHelpers () {
    let alreadyHasExceededMax = false;
    Object.keys(this.numberOfCompsTracker).forEach((key) => {
      if (this.numberOfCompsTracker[+key] > MAX_COMPONENTS_PER_PAGE) {
        alreadyHasExceededMax = true;
      }
    });
    this.enforceMaxComps = !alreadyHasExceededMax;
  }

  async hasTooManyComponentsOnPage (): Promise<boolean> {
    let preventChange = false;
    if (this.enforceMaxComps) {
      let numberOfComps = 0;
      this.componentHelper.eachComponent(this.currentFormDefinition.components, (comp) => {
        if (comp.type !== 'button') {
          numberOfComps = numberOfComps + 1;
        }
      });
      if (numberOfComps > MAX_COMPONENTS_PER_PAGE) {
        const newPage = await this.modalFactory.open(
          ComponentLimitMetModalComponent,
          {}
        );
        if (newPage) {
          this.onCreateNewPage.emit();
        }
        preventChange = true;
      }
    }

    return preventChange;
  }

  async handleEdit (
    component: FormDefinitionComponent
  ): Promise<boolean> {
    const audience = this.formBuilderService.currentFormBuilderFormAudience;
    const updatedComponent = await this.formBuilderService.editComponentModal(
      component,
      this.form.id,
      this.form.formDefinition,
      audience === FormAudience.MANAGER,
      false
    );
    if (updatedComponent) {
      this.componentHelper.removeOrReplaceFormComponent(
        component,
        this.currentFormDefinition,
        updatedComponent
      );

      return true;
    }

    return false;
  }

  handleCopy (
    component: FormDefinitionComponent
  ) {
    this.formBuilderService.handleCopyComponent(component);
  }

  handleCut (
    component: FormDefinitionComponent
  ) {
    this.formBuilderService.handleCutComponent(component, this.currentFormDefinition);
    const logicStates = this.formLogicService.initFormDefinitionLogic(this.form.formDefinition);
    const relatedFieldMap = this.formLogicService.getRelatedFieldMap(component, logicStates);
    const numberOfRelatedFields = Object.keys(relatedFieldMap).length;
    if (numberOfRelatedFields > 0) {
      this.hasInvalidCut = true;
    }
    this.form.formDefinition = [...this.form.formDefinition];
  }

/**
 *
 * @param relativeComponent component above, below, or containing the pasted component
 * @param pasteLocation where the pasted component should be insterted
 * @returns boolean for whether the component was successfully copied (saved in database if needed) and pasted
 */
  async handlePaste (
    relativeComponent: FormDefinitionComponent,
    pasteLocation: FormFieldPasteLocation
  ): Promise<boolean> {
    const componentToPaste = this.componentHelper.prepComponentForPaste(
      this.formBuilderService.copiedComponent,
      this.form.formDefinition
    );

    const refComponents = this.componentHelper.getRefFieldComponentsToCopy(
      componentToPaste,
      this.form.formDefinition
    );
    let proceed = false;
    if (refComponents.length > 0) {
      proceed = await this.confirmReferenceFieldPaste(
        refComponents,
        componentToPaste,
        relativeComponent,
        pasteLocation
      );
    } else {
      this.formBuilderService.handlePasteComponent(
        relativeComponent,
        this.currentFormDefinition,
        pasteLocation
      );
      proceed = true;
    }
    if (proceed) {
      this.hasInvalidCut = false;
    }

    return proceed;
  }

  async handleRemove (
    component: FormDefinitionComponent
  ): Promise<boolean> {
    const compDeletionText = this.formLogicService.getCompDeletionText(
      component,
      this.form.formDefinition
    );
    const result = await this.modalFactory.open(
      ConfirmationModalComponent,
      {
        modalHeader: this.i18n.translate(
          'CONFIG:hdrRemoveComponent',
          {},
          'Remove Component'
        ),
        modalSubHeader: component.label,
        confirmText: compDeletionText || this.i18n.translate(
          'CONFIG:textAreYouSureRemoveComponent2',
          {},
          'Are you sure you want to remove this component?'
        ),
        confirmButtonText: this.i18n.translate(
          !!compDeletionText ? 'common:textClose' : 'common:textRemove',
          {},
          !!compDeletionText ? 'Close' : 'Remove'
        )
      }
    );

    if (result && !compDeletionText) {
      const nestedComps = this.componentHelper.getNestedComponents(component);
      this.removedCompsWithinSession = [
        ...this.removedCompsWithinSession,
        ...nestedComps
      ];
      this.componentHelper.removeOrReplaceFormComponent(
        component,
        this.currentFormDefinition
      );

      return true;
    }

    return false;
  }

  async restoreDeletedComponents () {
    const builderGroups = this.formBuilderService.getBuilderGroups();
    const groupsToSkip: string[] = [
      builderGroups[BuilderConfigGroupType.Layout],
      builderGroups[BuilderConfigGroupType.OnForm]
    ];
    const componentsToRestore = await this.modalFactory.open(
      RestoreDeletedComponentsModalComponent,
      {
        deletedComponents: this.removedCompsWithinSession,
        bucketComps: this.toolboxPanelSections.reduce((acc, panel) => {
          if (!groupsToSkip.includes(panel.name)) {
            const bucketComps = panel.panels.reduce((acc, group) => {
              return [
                ...acc,
                ...group.context
              ];
            }, [] as BucketComp[]);

            return [
              ...acc,
              ...bucketComps
            ]
          } else {
            return [
              ...acc
            ];
          }
        
        }, [] as BucketComp[])
      }
    );
    if (!!componentsToRestore) {
      this.formBuilderService.insertComponents(this.currentFormDefinition, componentsToRestore);
      this.handleFormChange(this.form.formDefinition);
      const compKeysToRestore = componentsToRestore.map((comp) => comp.key);
      this.removedCompsWithinSession = this.removedCompsWithinSession.filter((comp) => {
        return !compKeysToRestore.includes(comp.key);
      });
    }
  }

  async confirmReferenceFieldPaste (
    refComponents: FormDefinitionComponent[],
    pastedComponent: FormDefinitionComponent,
    relativeComponent: FormDefinitionComponent,
    pasteLocation: FormFieldPasteLocation
  ): Promise<boolean> {
    let singleField: ReferenceFieldAPI.ReferenceFieldDisplayModel;
    if (refComponents.length === 1) {
      const key = this.componentHelper.getRefFieldKeyFromCompType(
        refComponents[0].type
      );
      singleField = this.formFieldHelperService.getReferenceFieldByKey(
        key
      );
    }
    const result = await this.modalFactory.open(
      ConfirmationModalComponent,
      {
        modalHeader: this.i18n.translate(
          singleField ?
            'FORMS:hdrPasteFormField' :
            'FORMS:hdrPasteFormFields',
          {},
          singleField ?
            'Paste Form Field' :
            'Paste Form Fields'
        ),
        modalSubHeader: singleField?.name || '',
        confirmText: singleField ?
          this.i18n.translate(
            'GLOBAL:textAreYouSurePasteRefField',
            {},
            'Are you sure you want to paste the form field? Pasting a copy of the form field will create a new form field with the same configuration.'
          ) :
          this.i18n.translate(
            'GLOBAL:textAreYouSurePasteFormFields',
            {},
            'Are you sure you want to paste the form fields? This will create new form fields with the same configurations.'
          ),
        confirmButtonText: this.i18n.translate(
          'common:textPaste',
          {},
          'Paste'
        )
      }
    );

    if (result) {
      this.spinnerService.startSpinner();
      await this.formBuilderService.proceedWithPasteRefFields(
        refComponents,
        pastedComponent,
        relativeComponent,
        this.currentFormDefinition,
        pasteLocation
      );
      this.spinnerService.stopSpinner();

      return true;
    }

    return false;
  }

  async spellCheck () {
    await this.formBuilderService.handleSpellCheck();
  }

  ngOnDestroy () {
     // clear entire picklist map
    this.formFieldHelperService.resetParentPicklistValueMap();
    this.footerService.clearAll();
  }
}
